import axios from "axios";
import StorageService from "config/StorageService";

import { API_CONTABLE, API_CUENTAS, API_INVENTARIO, API_REPORTES, API_WORKERS } from "config/api.config";
import { SESSION_USER } from "config/session";

const data = StorageService.get(SESSION_USER);

export const TOKEN = data?.token;
export const HEADQUARTER = data?.headquarter?._id;
export const USER = data?.user?._id;

export const InventoryAxios = axios.create({
  baseURL: `${API_INVENTARIO}`,
  headers: { Authorization: TOKEN },
});

export const AuthAxios = axios.create({
  baseURL: `${API_CONTABLE}`,
  headers: { Authorization: TOKEN },
});

export const ContableAxios = axios.create({
  baseURL: `${API_CUENTAS}`,
  headers: { Authorization: TOKEN },
});

export const ReportAxios = axios.create({
  baseURL: `${API_REPORTES}`,
  headers: { Authorization: TOKEN },
});

export const apiWorkersClient = axios.create({
  baseURL: API_WORKERS,
  headers: { Authorization: TOKEN },
});
